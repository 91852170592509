<template>
	<div
		class="contract-sign tw-bg-72 tw-bg-opacity-5 tw-flex-1 tw-p-0 xl:tw-p-8 tw-overflow-auto"
	>
		<v-container v-if="currentDetails">
			<v-card
				class="tw-max-w-3xl tw-mx-auto tw-rounded-xl tw-shadow tw-p-0 md:tw-p-2 lg:tw-p-6 xl:tw-p-8"
			>
				<v-card-title>{{ $t('contract-sign') }}</v-card-title>
				<v-card-subtitle>
					{{ $t('details-sign-contract') }}
				</v-card-subtitle>

				<v-divider class="tw-mx-4" />

				<v-card-text v-if="currentDetails">
					<vue-editor v-model="currentDetails.details" disabled />
				</v-card-text>

				<v-card-actions class="tw-px-4 tw-pb-4">
					<v-btn
						large
						depressed
						color="primary"
						class="tw-px-4"
						rounded
						@click="onSignContract"
					>
						<span class="tw-normal-case">
							{{ $t('signContract') }}
						</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_ROUTE } from '../consts'
import { VueEditor } from 'vue2-editor'

// import Default from '../layouts/Default.vue'

export default {
	name: 'ContractSign',
	components: { VueEditor },
	created() {
		this.getContractsDetails()
	},
	computed: {
		...mapGetters('contracts', ['$contractDetails']),
		...mapGetters('auth', ['$currentUser']),
		currentDetails() {
			return this.$contractDetails.data.find(
				cd => cd.type === this.$currentUser.role
			)
		},
	},
	methods: {
		...mapActions('auth', ['fetchProfile']),
		...mapActions('contracts', ['getContractsDetails', 'signContract']),
		async onSignContract() {
			let [err] = await this.signContract({
				contractDetailId: this.currentDetails.id,
				contractableType: this.currentDetails.type,
				startingDate: this.$m().format('YYYY-MM-DD'),
				contractableId: this.$currentUser.isBuyer
					? this.$currentUser.buyer[0].id
					: this.$currentUser.supplier[0].id,
			})
			if (err) return this.$toast.error(this.$t('errorSigningContract'))
			this.$toast.success(this.$t('successSigningContract'))

			await this.fetchProfile()
			this.$router.push(DEFAULT_ROUTE[this.$currentUser.role])
		},
	},
}
</script>

<style lang="scss">
.contract-sign {
	.ql-toolbar {
		display: none !important;
	}
	.ql-container {
		border: none !important;
	}
}
</style>
